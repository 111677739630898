/*
 * Contents Box Style
*/


/* Module */

.l-column {
    @include baseWidth;
    display: -ms-flexbox;display: flex;
    margin-bottom: 60px;
    @media (max-width: $breakMaxTablet) {
        flex-direction: column;
    }
    .l-column__sub {
        box-sizing: border-box;
        width: 260px;
        padding-top: 40px;
        @media (max-width: $breakMaxTablet) {
            width: 100%;
        }
    }
    .l-column__main {
        -ms-flex: 1;flex: 1;
        padding-left: 44px;
        @media (max-width: $breakMaxTablet) {
            padding-left: 0;
        }
    }
}

.l-main__min {
    padding: 30px 0;
    background: $green;
    @media (max-width: $breakMaxTablet) {
        padding: 15px;
    }
    .main-inner {
        @include baseWidth;
        padding-top: 30px;
        background-color: #fff;
    }
}

.l-notfound {
    @include baseWidth;
    max-width: 733px;
    margin-top: 80px;
    margin-bottom: 80px;
    padding-top: 70px;
    padding-bottom: 60px;
    padding-left: 342px;
    display: -ms-flexbox;display: flex;
    flex-direction: column;
    -ms-align-items: center;align-items: center;
    background-image: url(/images/bg_rai4_sorry.png);
    background-repeat: no-repeat;
    background-position: left center;
    @media (max-width: $breakMaxPhone) {
        max-width: 240px;
        margin-top: 0;
        padding-bottom: 180px;
        background-position: center bottom;
        background-size: auto 150px;
    }
    img {
        @include rspImage;
    }
    .btn-solid {
        width: 100%;
        max-width: 340px;
    }
}

.l-features {
    background: #fff772;
    margin-bottom: 40px;
}

.l-newest {
    @include baseWidth;
    margin-top: 90px;
    .btn-more {
        margin: 0 auto;
    }
    .line-dot {
        margin: 50px 0 80px;
        @media (max-width: $breakMaxPhone) {
            margin: 25px 0 40px;
        }
    }
}

.l-recommend {
    @include baseWidth;
    // margin-top: 90px;
    .btn-more {
        margin: 0 auto;
    }
    .line-dot {
        margin: 50px 0 80px;
        @media (max-width: $breakMaxPhone) {
            margin: 25px 0 40px;
        }

    }
}

.l-cat-recommend {
    .ttl {
        margin-bottom: 30px;
    }
}

.contents-cat-carousel {
    margin-bottom: 30px;
    padding: 25px 0 0;
    background-image: url($imgPath+'/bg_stripe.png');
    background-repeat: repeat;
}

.l-news {
    @include baseWidth;
    margin-bottom: 20px;
}

.l-rakuten {
    @include baseWidth;
    @media (max-width: $breakMaxPhone) {
        padding-left: 0;
        padding-right: 0;
    }
    .box-rakuten {
        box-sizing: border-box;
        position: relative;
        padding: 5px;
        margin-bottom: 20px;
        height: 90px;
        -ms-align-items: center;align-items: center;
        background-color: #b4191a;
        background-image: url(/images/banner_rakuten.png);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: auto 100%;
        @media (max-width: $breakMaxTablet) {
            background-image: none;
        }
        @media (max-width: $breakMaxPhone) {
            display: -ms-flexbox;display: flex;
            flex-direction: column-reverse;
            width: 100%;
            height: auto;
            margin: 0 auto 10px;
            padding: 5px 0;
        }
        &::after {
            position: absolute;
            content: '';
            display: inline-block;
            top: 50%;
            left: 200px;
            width: 398px;
            height: 73px;
            transform: translateY(-36px);
            background-image: url(/images/copy_rakuten.png);
            background-repeat: no-repeat;
            background-position: 0 50%;
            @media (max-width: $breakMaxTablet) {
                width: 100%;
                left: 0;
                background-position: 10px 50%;
                background-size: 40%;
            }
            @media (max-width: $breakMaxPhone) {
                display: block;
                position: relative;
                top: 0;
                left: 0;
                width: 90%;
                margin: 0 auto 5px;
                transform: translate(0);
                background-size: contain;
            }
        }
        &::before {
            position: absolute;
            content: '';
            display: inline-block;
            top: 50%;
            right: 342px;
            width: 86px;
            height: 27px;
            transform: translateY(-12px);
            background-image: url(/images/arrow_rakuten.png);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            @media (max-width: $breakMaxFablet) {
                display: none;
            }
        }
    }
}
#rakutenParts {
    display: block;
    position: absolute;
    top: 8px;
    right: 8px;
    // width: 110px;
    @media (max-width: $breakMaxPhone) {
        position: relative;
        top: 0;
        right: 0;
        margin: 0 auto;
        width: 300px;
    }
}

.l-smartapp {
    @include baseWidth;
    margin-top: 80px;
    margin-bottom: 30px;
    .smartapp-inner {
        position: relative;
        padding: 30px 100px 30px 412px;
        border: 5px solid $blue;
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 90px;
            top: -37px;
            width: 272px;
            height: 296px;
            background-image: url($imgPath+'/bg_rai4_smartphone.png');
            background-repeat: no-repeat;
            background-position: center;
        }
        @media (max-width: $breakMaxPhone) {
            padding-top: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
            padding-left: 320px;
            &:after {
                top: 20px;
                left: 20px;
            }
        }
        .ttl {
            margin-bottom: 18px;
            text-indent: -0.2rem;
        }
    }
}

.bnr-nologin {
	text-align: center;
	padding-top: 10px;
	margin-bottom: 0;
	@media (max-width: $breakMaxTablet) {
		padding: 10px 20px 0;
	}
	@media (max-width: $breakMaxFablet) {
		padding: 10px 20px 0;
		margin-bottom: -10px;
	}
	@media (max-width: $breakMaxPhone) {
		padding: 10px 5px 0;
	}
}
