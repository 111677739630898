/*
 * Icon Style
*/

// Atomic
*[class*="icon"]:before,
*[class*="icon"]:after {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
}

.icon-login {
    &:before {
        width: 26px;
        height: 27px;
        padding-right: 15px;
        background-image: url($imgPath+'/icon_key.png');
    }
}

.icon-registration {
    &:before {
        width: 26px;
        height: 27px;
        padding-right: 15px;
        background-image: url($imgPath+'/icon_new.png');
    }
}

.icon-guide {
    &:before {
        width: 25px;
        height: 25px;
        padding-right: 10px;
        background-image: url($imgPath+'/icon_free.png');
    }
    &:after {
        width: 25px;
        height: 25px;
        padding-left: 10px;
        background-image: url($imgPath+'/icon_beginner.png');
    }
}

.icon-mypage {
    &:before {
        width: 25px;
        height: 25px;
        padding-right: 10px;
        background-image: url($imgPath+'/icon_mypage.svg');
    }
}

.icon-category {
    background-image: url($imgPath+'/icon_category.png');
    background-repeat: no-repeat;

    &.black {
        background-image: url($imgPath+'/icon_category_black.png');
        background-size: 25px;
    }
    &.top {
        padding-top: 62px;
        background-position: top center;
    }
    &.left {
        padding-left: 40px;
        background-position: 10px center;
    }
}
.icon-menu {
    background-image: url($imgPath+'/icon_menu.png');
    background-repeat: no-repeat;
    &.black {
        background-image: url($imgPath+'/icon_menu.png');
        background-size: 15px;
    }
    &.left {
        padding-left: 40px;
        background-position: 15px center;
    }
}

.icon-arrow {
    display: -ms-flexbox;display: flex;
    justify-content: flex-start;
    -ms-align-items: center;align-items: center;
    &::before{
        background-image: url($imgPath+'/arrow_1.svg');
        background-repeat: no-repeat;
        background-size: 7px;
    }
    &.left::before {
        padding-left: 6px;
        background-position: left center;
    }
    &.right::after {
        padding-right: 6px;
        background-position: right center;
    }
    &.white::after {
        display: inline-block;
        width: 12px;
        height: 20px;
        padding-left: 10px;
        background-image: url($imgPath+'/img_arrow_r.png');
        background-position: center;
    }
    &.white_prev::before {
        display: inline-block;
        width: 12px;
        height: 20px;
        padding-right: 10px;
        background-image: url($imgPath+'/img_arrow_l.png');
        background-position: center;
    }
    &.red::before {
        width: 8px;
        height: 8px;
        background-image: url($imgPath+'/arrow_red.svg');
    }
    &.yellow::before {
        width: 8px;
        height: 8px;
        background-image: url($imgPath+'/arrow_yellow.svg');
    }
}

.icon-arrow__flat {
    background-image: url($imgPath+'/arrow_flat.svg');
    background-repeat: no-repeat;
    &.bottom {
        padding-bottom: 80px;
        background-position: center bottom;
    }
}

.icon-account {
    background-image: url($imgPath+'/icon_account.svg');
    background-repeat: no-repeat;
    &.left {
        padding-left: 35px;
        background-position: 10px center;
    }
    &.top {
        padding-top: 40px;
        background-position: center 10px;
    }
    &.white {
        background-image: url($imgPath+'/icon_account_2.svg');
    }
}

.icon-play {
    &:before {
        width: 20px;
        height: 20px;
        padding-left: 8px;
        background-image: url($imgPath+'/icon_play.svg');
    }
}

.icon-fb {
    &:before {
        width: 12px;
        height: 24px;
        padding-right: 8px;
        background-image: url($imgPath+'/icon_fb.svg');
    }
}
.icon-tw {
    &:before {
        width: 21px;
        height: 17px;
        padding-right: 8px;
        background-image: url($imgPath+'/icon_tw.svg');
    }
}
.icon-li {
    &:before {
        width: 30px;
        height: 30px;
        padding-right: 8px;
        background-image: url($imgPath+'/icon_line.svg');
    }
}
