/*
 * Reset Style
*/
html, body, div, span, object, iframe,h1, h2, h3, h4, h5, h6, p, pre, address, em, img, small, strong, b, i,
dl, dt, dd, ol, ul, li, form, label, table, caption, tbody, tfoot, thead, tr, th, td, article, canvas, footer,
header, menu, nav, section, time, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
}
body { line-height: 1; }
article, footer, header, menu, nav, section { display: block; }
ol,ul { list-style: none; }
a {
    outline: none;
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
em, b { font-style: normal; }
img { vertical-align: top; }
hr {
    display: block;
    height: 1px;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
input, select { vertical-align: middle; }
button{
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
}