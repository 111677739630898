/* 
 * Contact Pages Style
 */

// Page
#contact {
    .component-contact {
        margin-bottom: 40px;
    }
    .contact-wrapper {
        padding: 20px;
        background-image: url(/images/bg_stripe.png);
        background-repeat: repeat;
        @media (max-width: $breakMaxPhone) {
            padding: 10px;
            margin-left: $baseSpace/2*-1;
            margin-right: $baseSpace/2*-1;
        }
    }
    .contact-inner {
        padding: 40px;
        background: #fff;
        @media (max-width: $breakMaxPhone) {
            padding: 20px;
        }
    }
    dl {
        max-width: 600px;
        margin: 0 auto;
    }
    dt {
        margin-bottom: 20px;
    }
    dd {
        margin-bottom: 40px;
    }
    select {
        width: 100%;
    }
    input[type=submit] {
        width: 100%;
        max-width: 282px;
        margin: 0 auto 40px;
    }
    @media (max-width: $breakMaxPhone) {
        .l-column__main::before {
            display: block;
            content: "";
            width: 100%;
            height: 2px;
            margin-bottom: 40px;
            background: #FFF url('/images/border.png') top center repeat-x;
        }
        .l-column__sub {
            margin-bottom: 20px;
        }
        .ttl-header {
            display: none;
        }
    }
}