/*
 * Title Style
*/


/* Atomic */

.txt {
    &.large {
        font-size: 26px;
    }
    &.medium {
        font-size: 20px;
    }
    &.small {
        font-size: 14px;
    }
    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
    &.left {
        text-align: left;
    }
    &.red {
        color: #ff5c35;
    }
    &.gray {
        color: #666;
    }
    &.strong {
        font-weight: bold;
    }
    &.line {
        text-decoration: underline;
    }
}

.underline {
    text-decoration: underline;
}

.asterisk {
    text-indent: -1.3em;
    padding-left: 1em;
    &:before {
        content: '※ ';
    }
}
