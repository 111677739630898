/*
*  Atomic Design UI Parts Style
*  Parts Contents
- Bg Style
- Paragraph style
- Icon style
- Title Style
- Navigation & Menu Style
- Button Style
- Box Style
- List Style
- Separate Style
- Form Style
- Header Style
- Footer Style
- Modal Style
- Animation Style
*
*/

@import "parts/line";
@import "parts/icon";
@import "parts/txt";
@import "parts/btn";
@import "parts/image";
@import "parts/notify";
@import "parts/ttl";
@import "parts/form";
@import "parts/list";
@import "parts/header";
@import "parts/footer";
@import "parts/layout";

@import "parts/ukoffcanvas.scss";
@import "parts/addtohomescreen.scss";