/*
 * Fotter Style
*/


/* Module */

footer.l-footer {
    background: $greenDark;
    color: #FFF;
    // Others
    .footer-inner {
        @include baseWidth;
    }
    .footer-nav {
        padding: 40px 0;
        .list-social {
            display: -ms-flexbox;display: flex;
            -ms-justify-content: center;justify-content: center;
            margin-right: -10px;
            margin-left: -10px;
            a {
                width: 100%;
                max-width: 240px;
                margin-right: 10px;
                margin-left: 10px;
                img {
                    @include rspImage;
                }
            }
            @media (max-width: $breakMaxPhone) {
                flex-direction: column;
                -ms-align-items: center;align-items: center;
                a {
                    margin-bottom: 10px;
                }
            }
        }
        .btn-social {
            box-sizing: border-box;
            display: -ms-flexbox;display: flex;
            -ms-justify-content: center;justify-content: center;
            -ms-align-items: center;align-items: center;
            padding: 5px 10px;
            font-size: 16px;
            font-weight: bold;
            &:before {
                margin-right: 5px;
                transition: all .3s ease;
                -webkit-transition: all .3s ease;
            }
            &:hover:before {
                transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
            }
        }
        .list-subnav {
            margin-top: 30px;
            text-align: center;
            li {
                display: inline-block;
                padding-right: 10px;
                margin-right: 10px;
                margin-bottom: 10px;
                border-right: 1px solid #fff;
                &:last-child {
                    padding-right: 0;
                    border-right: none;
                }
                @media (max-width: $breakMaxPhone) {
                    position: relative;
                    display: block;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    border-right: none;
                    border-top: 1px solid lighten($greenDark, 5%);
                    &:last-child {
                        border-bottom: 1px solid lighten($greenDark, 5%);
                    }
                    &:before {
                        @include createStyle;
                        top: 50%;
                        left: 8px;
                        width: 12px;
                        height: 14px;
                        transform: translate(0,-50%);
                        background-image: url($imgPath+'/arrow_1.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }
            }
            a {
                color: #FFF;
                text-decoration: none;
                @media (max-width: $breakMaxPhone) {
                    display: block;
                    padding: 20px 30px;
                    text-align: left;
                }
            }
        }
    }
    .list-authentication {
        display: -ms-flex;
        display: flex;
        -ms-justify-content: center;
        justify-content: center;
        -ms-align-items: center;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 20px;
        text-align: center;
        li {
            display: -ms-flex;
            display: flex;
            -ms-align-items: center;
            align-items: center;
            margin-bottom: 20px;
            font-size: 11px;
        }
        img {
            @include rspImage;
        }
        span {
            padding: 0 10px;
            text-align: left;
        }
        @media (max-width: $breakMaxPhone) {
            display: block;
            padding: 0 20px;
            img {
                width: 10%;
            }
            span {
                width: 90%;
            }
            br {
                display: none;
            }
        }
    }
    .txt-copyright {
        margin: 0;
        padding: 20px 10px;
        background: darken($greenDark, 10%);
        text-align: center;
        font-size: 14px;
        @media (max-width: $breakMaxPhone) {
            font-size: 10px;
            line-height: 1.2;
        }
    }
}
