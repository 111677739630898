/* 
 * Login Pages Style
 */

// Page
#signup {
    .ttl-dot {
        text-indent: -24px;
        padding-left: 24px;
        line-height: 1.7;
        margin-bottom: 15px;
        &::before {
            content: '';
            background-image: url($imgPath+'/icon_dot.png');
            background-repeat: no-repeat;
            background-position: right center;
            width: 10px;
            height: 10px;
            margin-right: 14px;
            display: inline-block;
        }
    }
    .list-steps {
        padding: 20px 0;
    }
    .form-wrapper {
        box-sizing: border-box;
        width: 100%;
        max-width: 588px;
        min-height: 600px;
        padding-bottom: 30px;
        margin: 0 auto;
        @media (max-width: $breakMaxPhone) {
            padding: 20px 0;
        }
        .ttl-line {
            margin-bottom: 20px;
        }
        .box-flex {
            margin-right: -20px;
            margin-left: -20px;
            @media (max-width: $breakMaxPhone) {
                margin-right: 0;
                margin-left: 0;
            }
        }
        .btn-solid {
            cursor: pointer;
            padding: 18px 10px;
            margin: 60px 20px 30px;
            width: 100%;
            max-width: 400px;
            @media (max-width: $breakMaxPhone) {
                margin: 0 auto 30px;
                width: 100%;
            }
        }
        input[type="submit"] {
            // order: 2;
        }
        .contents-url {
            padding-right: 20px;
            @media (max-width: $breakMaxPhone) {
                padding-right: 0;
            }
        }
        .txt-url {
            padding: 10px;
            border: 1px solid $gray;
            word-wrap: break-word;
            word-break: break-all;
        }
        .contents-qr {
            text-align: center;
            img {
                border: 5px solid $green;
            }
        }
        .form-group {
            margin-bottom: 60px;
        }
        dl.tbl-form {
            display: -ms-flexbox;
            display: flex;
            -ms-align-items: center;
            align-items: center;
            margin-bottom: 20px;
            > dt {
                width: 200px;
            }
            > dd {
                -ms-flex: 1;
                flex: 1;
                &.no-title {
                    margin-left: 200px;
                }
            }
            @media (max-width: $breakMaxPhone) {
                display: block;
                width: 100%;
                > dt {
                    margin-bottom: 10px;
                }
                > dd {
                    &.no-title {
                        margin-top: 50px;
                        margin-left: 0;
                        text-align: center;
                    }
                }
            }
        }
        .annotation {
            margin-top: -15px;
            margin-bottom: 20px;
        }
        .btn-group {
            margin-bottom: 60px;
            .btn-payment {
                box-sizing: border-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                align-items: center;
                height: 60px;
                padding: 10px 15px;
                border: 1px solid $green;
                // background-color: #ecf2b7;
                border-radius: 6px;
                font-weight: bold;
                font-size: 18px;
                &:hover {
                    opacity: .65;
                    transition: opacity .3s;
                }
                &.credit {
                    > span,
                    > img {
                        width: 50%;
                        height: auto;
                    }
                    @media (max-width: $breakMaxPhone) {
                        height: auto;
                        > span,
                        > img {
                            width: 100%;
                            margin: 5px 0;
                        }
                    }
                }
                > i,
                > span,
                > img {
                    max-width: 100%;
                }
            }
        }
    }
    .page-payment {
        .box-current {
            background-color: $grayLight;
            padding: 30px 30px 15px;
            margin-bottom: 30px;
            dl {
                padding-bottom: 15px;
                font-size: 14px;
                dt,
                dd {
                    display: inline-block;
                }
                dt {
                    width: 250px;
                    font-weight: bold;
                }
            }
        }
    }
    .tbl-signup {
        width: 100%;
        margin-bottom: 30px;
        border-top: 1px solid $gray;
        border-left: 1px solid $gray;
        th,
        td {
            border-bottom: 1px solid $gray;
            border-right: 1px solid $gray;
            @media (max-width: $breakMinFablet) {
                &:first-child {
                    display: none;
                }
            }
        }
        th {
            padding: 10px 0;
            text-align: center;
            background-color: #eaf398;
        }
        td {
            padding: 23px 0;
            text-align: center;
            font-size: 16px;
            @media (max-width: $breakMinFablet) {
                font-size: .8em;
            }
        }
    }
    .mobile-cancel {
        display: -ms-flexbox;
        display: flex;
        -ms-justify-content: center;
        justify-content: center;
        -ms-align-items: center;
        align-items: center;
    }
    .box-rakuten {
        display: -ms-flex; display: flex;
        -ms-justify-content: space-between; justify-content: space-between;
        width: 100%;
        @media (max-width: $breakMaxPhone) {
            -ms-flex-direction: column; flex-direction: column;
        }
        h2 {
            margin-bottom: 10px;
        }
        input {
            padding: 0;
            border: none;
            border-radius: 0;
            margin-right: 30px;
        }
    }
    // GMO　Payment
    .box-gmo-steps {
        margin: 40px 0;
        @media (max-width: $breakMaxPhone) {
            margin: 20px 0;
        }
    }
    .ttl-gmo {
        font-size: 26px;
        text-align: center;
        @media (max-width: $breakMaxPhone) {
            font-size: 20px;
        }
    }
    ul.list-gmo-steps {
        text-align: center;
        margin: 0 0 40px;
        @media (max-width: $breakMaxPhone) {
            margin: 0 0 20px;
            overflow: hidden;
            text-align: left;
        }
    }
    ul.list-gmo-steps li {
        display: inline-block;
        margin: 0 30px;
        color: #ccc;
        font-weight: bold;
        @media (max-width: $breakMaxPhone) {
            width: 100%;
        }
    }
    ul.list-gmo-steps li.active {
        color: #6eaa49;
    }
    ul.list-gmo-steps li::after {
        display: inline-block;
        content: "";
        margin-left: 50px;
        width: 12px;
        height: 13px;
        background: url($imgPath+'/arrow_gray.svg') center no-repeat;
        @media (max-width: $breakMaxPhone) {
            display: none;
        }
    }
    ul.list-gmo-steps li.active::after {
        background: url($imgPath+'/arrow_green_dark.svg') center no-repeat;
        @media (max-width: $breakMaxPhone) {
            display: none;
        }
    }
    ul.list-gmo-steps li:last-child::after {
        display: none;
    }
    .form-gmo {
        select {
            margin-left: 20px;
            margin-right: 7px;
            &:first-child {
                margin-left: 0;
            }
        }
        input {
            width: 100%;
        }
        input[name=action_MemberSaveConf] {
            box-sizing: border-box;
            display: block;
            cursor: pointer;
            padding: 18px 10px;
            margin: 60px 20px 30px;
            width: 100%;
            max-width: 400px;
            background-color: #6fc0e1;
            box-shadow: 0 5px #2ca2d1;
            border: none;
            border-radius: 5px;
            text-align: center;
            color: #FFF;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
        }
    }
    div#GP_msg ul {
        padding: 20px;
        background-color: $grayLight;
        font-weight: bold;
        color: $error;
        background-color: $errorBg;
        li {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .ttl-registered_card,
    .ttl-register_card,
    .ttl-error_card {
        margin-top: 40px;
        margin-bottom: 10px;
        text-indent: -24px;
        padding-left: 24px;
        line-height: 1.7;
        &::before {
            content: '';
            background-image: url(../images/icon_dot.png);
            background-repeat: no-repeat;
            background-position: right center;
            width: 10px;
            height: 10px;
            margin-right: 14px;
            display: inline-block;
        }
    }
    .box-registered {
        dl.tbl-form {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
    .box-error {
        .btn-solid {
            margin: 40px auto 80px;
            max-width: 240px;
        }
    }
    .box-emphasis {
        padding: .3em .5em;
        background: #fadbda;
    }
}
