/*
 * Category Pages Style
 */

// Page
div[class^=component-cat] {
    .l-cat-recommend {
        margin-top: 30px;
        margin-bottom: 40px;
    }

    .l-programs {
        @include baseWidth;
        .line-dot {
            margin-bottom: 80px;
            @media (max-width: $breakMaxPhone) {
                margin-bottom: 0;
            }
        }
        .contents-programs {
            margin-bottom: 110px;
            .ttl-header {
                margin-bottom: 20px;
            }
        }
    }
}
