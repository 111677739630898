/*
 * Header Style
*/
.static-site__logo {
    margin: $paddingHeader auto;
    width: 220px;
    text-align: center;
    @media (max-width: $breakMaxFablet) {
        margin: $paddingHeader/2 auto;
        width: 108px;
    }
}

header .l-header__min {
    .logo {
        margin: $paddingHeader auto;
        width: 220px;
        text-align: center;
        @media (max-width: $breakMaxFablet) {
            margin: $paddingHeader/2 auto;
            width: 108px;
        }
    }
}
