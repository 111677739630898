/*
 * Tokushou Pages Style
 */

// Page
#tokushou {
    .tbl-tokushou {
        width: 100%;
        margin-bottom: 30px;
        border-top: 1px solid $gray;
        border-left: 1px solid $gray;
        th,
        td {
            border-bottom: 1px solid $gray;
            border-right: 1px solid $gray;
        }
        th {
            padding: 10px 20px;
            background-color: #eaf398;
            width: 300px;
        }
        td {
            padding: 15px 20px;
            font-size: 16px;
        }
        @media (max-width: $breakMinFablet) {
            th,
            td {
                width: 100%;
                display: block;
                font-size: .8em;
            }
        }
    }

    @media (max-width: $breakMaxPhone) {
        .l-column__main::before {
            display: block;
            content: "";
            width: 100%;
            height: 2px;
            margin-bottom: 40px;
            background: #FFF url($imgPath+'/border.png') top center repeat-x;
        }
        .l-column__sub {
            margin-bottom: 20px;
        }
        .ttl-header {
            display: none;
        }
    }
}
