/*
 * Title Style
*/


/* Atomic */

.ttl {
    font-weight: bold;
    &.primary {
        font-size: 24px;
        @media (max-width: $breakMaxPhone) {
            font-size: 20px;
        }
    }
    &.secondary {
        font-size: 20px;
    }
    &.center {
        text-align: center;
    }
    &.left {
        text-align: left;
    }
    &.right {
        text-align: right;
    }
    &.blue {
        color: $blue;
    }
}

.ttl-line {
    padding-top: 8px;
    padding-bottom: 8px;
    border-style: solid;
    border-color: $green;
    font-size: 20px;
    font-weight: bold;
    @media (max-width: $breakMaxPhone) {
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 18px;
    }
    &.top {
        padding-top: 18px;
        border-top-width: 4px;
    }
    &.left {
        padding-left: 18px;
        border-left-width: 4px;
    }
    &.bottom {
        padding-bottom: 18px;
        border-bottom-width: 4px;
    }
    &.right {
        padding-right: 18px;
        border-right-width: 4px;
    }
}

.ttl-page__sp {
    display: none;
    position: relative;
    margin-bottom: 0;
    padding: 18px 0;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    background-color: $yellow;
    border-top: 5px solid $green;
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -15px;
        left: 50%;
        width: 0;
        height: 0;
        transform: translate(-50%, 0);
        border-style: solid;
        border-width: 18px 15px 0 15px;
        border-color: $yellow transparent transparent transparent;
    }
    @media (max-width: $breakMaxFablet) {
        display: block;
    }
}


/* Module */

.ttl-round {
    max-width: 640px;
    margin: 0 auto;
    line-height: 40px;
    border-radius: 20px;
    background-color: #FFF;
    font-size: 20px;
    color: $orange;
    text-align: center;
    @media (max-width: $breakMaxPhone) {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 18px;
    }
}

.ttl-header {
    box-sizing: border-box;
    padding: 10px;
    background-image: url($imgPath+'/ttl_bg_tri.png');
    background-position: center right;
    background-repeat: no-repeat;
    background-color: $green;
    max-height: 54px;
    font-size: 24px;
    color: #FFF;
    @media (max-width: $breakMinPhone) {
        margin-left: $baseSpace/2*-1;
        margin-right: $baseSpace/2*-1;
        text-align: center;
        font-size: 18px;
        background-image: none;
    }
    &.label-rai4-new {
        position: relative;
        margin-top: 42px;
        padding-left: 130px;
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -54px;
            left: 0;
            width: 111px;
            height: 149px;
            background-repeat: no-repeat;
            background-image: url($imgPath+'/ttl_newest_rai4.png');
            @media (max-width: $breakMinPhone) {
                background-image: none;
            }
        }
        @media (max-width: $breakMinPhone) {
            padding-left: 20px;
        }
    }
    &.label-rai4-recommend {
        position: relative;
        margin-top: 42px;
        padding-left: 130px;
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -54px;
            left: 10px;
            width: 111px;
            height: 149px;
            background-repeat: no-repeat;
            background-image: url($imgPath+'/ttl_recommend_rai4.png');
            @media (max-width: $breakMinPhone) {
                background-image: none;
            }
        }
        @media (max-width: $breakMinPhone) {
            padding-left: 20px;
        }
    }
    &.label-norai4 {
        position: relative;
        margin-top: 42px;
        padding-left: 20px;
    }
}
