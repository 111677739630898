/*
 * Form Style
*/
/* reset */
input, button, textarea, select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
$form-radius: 5px;

/* atomic */
input {
    outline: none;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid $gray;
    border-radius: $form-radius;
    font-size: 18px;
    &[type=text],
    &[type=password],
    &[type=email] {
        width: 100%;
    }
    &[type="reset"],
    &[type="submit"] {
        cursor: pointer;
    }
    &:disabled {
        cursor: default!important;
    }
}

select {
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    background-color: #fff;
    border: 1px solid $gray;
    border-radius: $form-radius;
    font-size: 14px;
    > option span {
        font-size: 12px;
    }
}

textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border: 1px solid $gray;
    border-radius: $form-radius;
    font-size: 14px;
}

/* Checkbox */
input[type="checkbox"] {
    display: none;
    & + label {
        position: relative;
        display: inline-block;
        padding: 3px 3px 3px 22px;
        cursor: pointer;
    }
    & + label::before {
        position: absolute;
        content: '';
        top: 15px;
        left: 0;
        width: 14px;
        height: 14px;
        margin-top: -8px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: $form-radius;
    }
}
input[type="checkbox"]:checked + label::after {
    position: absolute;
    content: '';
    top: 8px;
    left: 3px;
    width: 13px;
    height: 5px;
    border-left: 2px solid $pink;
    border-bottom: 2px solid $pink;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/* Radio */
input[type="radio"] {
    display: none;
    & + label {
        position: relative;
        display: inline-block;
        padding: 3px 3px 3px 20px;
        cursor: pointer;
    }
    & + label::before,
    & + label::after {
        position: absolute;
        content: '';
        top: 50%;
        border-radius: 100%;
        -webkit-transition: all .2s;
        transition: all .2s;
    }
    & + label::before {
        left: 0;
        width: 14px;
        height: 14px;
        margin-top: -8px;
        background: #f3f3f3;
        border: 1px solid #ccc;
    }
    & + label:hover::before {
        background: #fff;
    }
    & + label::after {
        opacity: 0;
        left: 4px;
        width: 8px;
        height: 8px;
        margin-top: -4px;
        background: #3498db;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}
input[type="radio"]:checked + label::before {
    background: #fff;
    border: 1px solid #3498db;
}
input[type="radio"]:checked + label::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* Label */
.label {
    display: inline-block;
    padding: 1px 3px;
    margin-right: 5px;
    font-size: .8em;
    &.require {
        color: #FFF;
        background: $pink;
    }
    &.optional {
        color: #FFF;
        background: $gray;
    }
}
