/*
 * Btn Style
*/

// atomic
.notify {
    padding: 20px;
    background-color: $grayLight;
    font-weight: bold;
    &.error {
        color: $error;
        background-color: $errorBg;
    }
}
