/* 
 * Login Pages Style
 */

// Page
#reset {
    .form-wrapper {
        padding: 60px;
        min-height: 600px;
        @media (max-width: $breakMaxPhone) {
            padding: 20px 0;
        }
        &,& + .line-dot {
            margin: 0 auto;
            max-width: 790px;
        }
        .ttl-line {
            margin-bottom: 20px;
        }
        dd {
            margin-bottom: 30px;
        }
        input[type="submit"],
        .signup {
            cursor: pointer;
            margin: 60px auto 30px;
            width: 300px;
            @media (max-width: $breakMaxPhone) {
                width: 100%;
            }
        }
    }
}