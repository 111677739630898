/*
 * QandA Pages Style
 */

// Page
#qa {
    .list-qa {
        ul {
            display: -webkit-flex;
            /* Safari */
            display: -ms-flexbox;display: flex;
            -webkit--ms-flex-wrap: wrap;flex-wrap: wrap;
            /* Safari */
            -ms-flex-wrap: wrap;flex-wrap: wrap;
            border-top: 1px solid $green;
            border-left: 1px solid $green;
            li {
                width: 33.333333%;
                a {
                    display: block;
                    border-right: 1px solid $green;
                    border-bottom: 1px solid $green;
                    padding: 18px 20px;
                    &::before {
                        content: '';
                        display: inline-block;
                        background-image: url($imgPath+'/arrow_b_green.svg');
                        width: 13px;
                        height: 11px;
                        margin-right: 10px;
                        vertical-align: middle;
                    }
                    &:hover {
                        background-color: $greenLight;
                    }
                }
            }
        }
    }
    dl.qa {
        dt {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 10px;
            text-indent: -30px;
            padding-left: 30px;
            &::before {
                content: '';
                background-image: url($imgPath+'/icon_q.svg');
                background-repeat: no-repeat;
                background-position: right center;
                width: 24px;
                height: 24px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 6px;
            }
        }
        dd {
            background-image: url($imgPath+'/border2.png');
            background-repeat: repeat-x;
            background-position: left bottom;
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }
    @media (max-width: $breakMaxPhone) {
        .l-column__main::before {
            display: block;
            content: "";
            width: 100%;
            height: 2px;
            margin-bottom: 40px;
            background: #FFF url($imgPath+'/border.png') top center repeat-x;
        }
        .l-column__sub {
            margin-bottom: 20px;
        }
        .ttl-header {
            display: none;
        }
        .list-qa {
            ul {
                flex-direction: column;
                li {
                    width: 100%;
                }
            }
        }
    }
}
