/*
 * Policy Pages Style
 */

// Page
#policy {
    ul.list-dot {
        li {
            text-indent: -24px;
            padding-left: 24px;
            line-height: 1.7;
            margin-bottom: 15px;
            &::before {
                content: '';
                background-image: url($imgPath+'/icon_dot.png');
                background-repeat: no-repeat;
                background-position: right center;
                width: 10px;
                height: 10px;
                margin-right: 14px;
                display: inline-block;
            }
        }
    }

    @media (max-width: $breakMinTablet) {
        .l-column__main::before {
            display: block;
            content: "";
            width: 100%;
            height: 2px;
            margin-bottom: 40px;
            background: #FFF url($imgPath+'/border.png') top center repeat-x;
        }
        .l-column__sub {
            margin-bottom: 20px;
        }
        .ttl-header {
            display: none;
        }
    }
}
