/*
 * Mixin Style
*/
@mixin baseWidth {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    max-width: $baseWidth + ($baseSpace*2);
    padding-left: $baseSpace;
    padding-right: $baseSpace;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: $breakMaxPhone) {
        padding-left: $baseSpace/2;
        padding-right: $baseSpace/2;
    }
}
@mixin clearfix {
    &::after { content: ""; display: block; clear: both; }
}
@mixin createStyle {
    content: "";
    display: block;
    position:absolute;
}
@mixin ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
@mixin rspImage {
    width: auto;
    height: auto;
    max-width: 100%;
}
@mixin heightFitImg {
    position: relative;
    overflow: hidden;
    img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,0);
        width: auto;
        height: 100%;
    }
}
@mixin grid($n) {
    box-sizing: border-box;
    @if $n < 1 {
        width: 100%*$n;
    } @else {
        width: 100%/$n;
    }
}
@mixin borderRadius($n) {
    -webkit-border-radius: $n + px;
       -moz-border-radius: $n + px;
        -ms-border-radius: $n + px;
            border-radius: $n + px;
}
@mixin fontVw ($size, $window) {
    font-size: $size/$window*100 + vw;
}