/*
 * List Style
*/


/* Module */

.list-horizontal {
    display: -ms-flexbox;display: flex;
    -webkit--ms-justify-content: center;justify-content: center;
    -ms-justify-content: center;justify-content: center;
    > li {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
    @media (max-width: $breakMaxPhone) {
        display: block;
        > li {
            display: block;
            margin: 0 auto;
        }
    }
}

.list-steps {
    display: -ms-flexbox;display: flex;
    -ms-justify-content: center;justify-content: center;
    > li {
        box-sizing: border-box;
        width: 234px;
        line-height: 40px;
        padding: 0 15px;
        background-image: url($imgPath+'/step_arrow_off.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left center;
        color: $gray;
        font-size: 14px;
        @media (max-width: $breakMinFablet) {
            @include fontVw(12, 768);
        }
        @media (max-width: $breakMinPhone) {
            @include fontVw(10, 320);
            padding: 0 10px;
            background-image: url($imgPath+'/step_arrow_off_sp.svg');
        }
        &:last-child {
            background-image: url($imgPath+'/step_square_off.svg');
            @media (max-width: $breakMinPhone) {
                background-image: url($imgPath+'/step_square_off_sp.svg');
            }
        }
        &.active {
            background-image: url($imgPath+'/step_arrow_on.svg');
            color: #fff;
            font-weight: bold;
            @media (max-width: $breakMinPhone) {
                background-image: url($imgPath+'/step_arrow_on_sp.svg');
            }
        }
        &.active:last-child {
            background-image: url($imgPath+'/step_square_on.svg');
            @media (max-width: $breakMinPhone) {
                background-image: url($imgPath+'/step_square_on_sp.svg');
            }
        }
    }
}

.list-gnav {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    background: $green;
    @media (max-width: $breakMaxPhone) {
        padding-top: 15px;
    }
    .list-gnav-inner {
        @include baseWidth;
        display: table;
        .btn-nav {
            display: table-cell;
            border-left: 1px solid $green;
            &:last-child {
                border-right: 1px solid $green;
                
                // センバツ用
                background-color: lighten($blue, 20%);
                &.active,
                &:hover,
                &:active {
                    background-color: lighten($blue, 10%);
                    transition: all .3s ease;
                }
                &.active {
                    &:after {
                        border-color: lighten($blue, 10%) transparent transparent transparent;
                    }
                }
            }
            img {
                max-width: 100px;
            }
            span {
                display: block;
            }
        }
    }
}

.list-nav__side {
    a {
        font-size: 16px;
        font-weight: bold;
        @media (max-width: $breakMaxPhone) {
            @include fontVw(13, 320);
        }
    }
    @media (min-width: $breakMinTablet) {
        border: 2px solid $green;
        > li {
            border-bottom: 1px solid $green;
            &:last-child {
                border: none;
            }
            a {
                position: relative;
                display: block;
                padding: 20px;
                &.active,
                &:hover,
                &:active {
                    background: $greenLight;
                    transition: all .3s ease;
                }
                &::after {
                    position: absolute;
                    content: '';
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    width: 10px;
                    height: 10px;
                    background-image: url($imgPath+'/arrow_green.svg');
                    background-repeat: no-repeat;
                    background-position: right center;
                }
            }
        }
    }
    @media (max-width: $breakMaxTablet) {
        li {
            display: inline-block;
            a {
                display: inline-block;
                padding-left: 12px;
                margin-right: 10px;
                background-image: url($imgPath+'/arrow_red.svg');
                background-repeat: no-repeat;
                background-position: left center;
                margin-bottom: 10px;
            }
        }
    }
}
