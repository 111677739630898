/*
 * Base Style
*/
[v-cloak] {
    display: none;
}

html {}

body {
    margin: 0;
    padding: 0;
    background: $baseBgColor;
    font-size: 16px;
    text-align: left;
    font-family: $fontfamily;
    line-height: 1.4;
    color: $baseBlack;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 1em;
}

section {
    position: relative;
}

a,
a:active,
a:hover {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
}

.show-desktop {
    @media (max-width: $breakMaxTablet) {
        display: none!important;
    }
}
.hide-desktop {
    @media (min-width: $breakMinTablet) {
        display: none!important;
    }
}
.show-tablet {
    @media (min-width: $breakMinTablet) {
        display: none!important;
    }
    @media (max-width: $breakMaxFablet) {
        display: none!important;
    }
}
.hide-tablet {
    @media (min-width: $breakMinFablet) and (max-width: $breakMaxTablet) {
        display: none!important;
    }
}
.show-fablet {
    @media (min-width: $breakMinFablet) {
        display: none!important;
    }
    @media (max-width: $breakMaxPhone) {
        display: none!important;
    }
}
.hide-fablet {
    @media (min-width: $breakMinPhone) and (max-width: $breakMaxFablet) {
        display: none!important;
    }
}
.show-phone {
    @media (min-width: $breakMinPhone) {
        display: none!important;
    }
}
.hide-phone {
    @media (max-width: $breakMaxPhone) {
        display: none!important;
    }
}

.box-flex {
    display: -ms-flexbox;display: flex;
            -ms-justify-content: center;justify-content: center;
            -ms-align-items: center;align-items: center;

    &.reverse {
        flex-direction: row-reverse;
    }
    &.stretch {
        -ms-align-items: stretch;align-items: stretch;
    }
    @media (max-width: $breakMinPhone) {
        &,
        &.reverse {
            flex-direction: column;
        }
    }
}

@for $i from 1 through 6 {
  .mb#{$i} { margin-bottom: $i*10px; }
}

.box-copyright {
    @include baseWidth;
    margin-bottom: 50px;
    font-size: 12px;
    color: rgba(0,0,0,.35);
}