#top {
    .contents-cat-carousel {
        padding-bottom: 20px;
        .btn-round {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            .icon-more:before {
                content: '';
                width: 7px;
                height: 14px;
                padding-left: 5px;
                background-repeat: no-repeat;
                background-size: 7px;
                background-image: url($imgPath+'/arrow_red.svg');
            }

            &:hover {
                .icon-more:before {
                    background-image: url($imgPath+'/arrow_yellow.svg');
                }
            }
        }
    }
}
