/* 
 * Guide Pages Style
 */

// Page
#guide {
    .tbl-guide {
        width: 100%;
        margin-bottom: 30px;
        border-top: 1px solid $gray;
        border-left: 1px solid $gray;
        th,
        td {
            border-bottom: 1px solid $gray;
            border-right: 1px solid $gray;
            @media (max-width: $breakMinFablet) {
                &:first-child {
                    display: none;
                }
            }
        }
        th {
            padding: 10px 0;
            text-align: center;
            background-color: #eaf398;
        }
        td {
            padding: 23px 0;
            text-align: center;
            font-size: 16px;
            @media (max-width: $breakMinFablet) {
                font-size: .8em;
            }
        }
    }
    .box-payment {
        margin-bottom: 20px;
        a {
            width: 257px;
            @media (max-width: $breakMaxPhone) {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .box-process {
        background-image: url($imgPath+'/arrow_flat.svg');
        background-repeat: no-repeat;
        padding-bottom: 30px;
        margin-bottom: 20px;
        background-position: 200px bottom;
        background-size: 45px 20px;
        &:last-child {
            background: none;
            padding-bottom: 0;
        }
        @media (max-width: $breakMaxFablet) {
            background-position: center bottom;
        }
        h4 {
            margin-bottom: 15px;
        }
        h4[class*=icon-process] {
            display: -ms-flexbox;display: flex;
            &::before {
                display: block;
                content: "";
                width: 24px;
                height: 24px;
                margin-right: 10px;
                background-repeat: no-repeat;
                background-position: left center;
                background-size: contain;
            }
        }
        .icon-process_1 {
            &::before {
                background-image: url($imgPath+'/listmark_1.svg');
            }
        }
        .icon-process_2 {
            &::before {
                background-image: url($imgPath+'/listmark_2.svg');
            }
        }
        .icon-process_3 {
            &::before {
                background-image: url($imgPath+'/listmark_3.svg');
            }
        }
        .icon-process_4 {
            &::before {
                background-image: url($imgPath+'/listmark_4.svg');
            }
        }
		.inr_btn {
			width: 200px;
			@media (max-width: $breakMaxPhone) {
				width: 100%;
			}
		}
    }
    @media (max-width: $breakMinTablet) {
        .l-column__main::before {
            display: block;
            content: "";
            width: 100%;
            height: 2px;
            margin-bottom: 40px;
            background: #FFF url($imgPath+'/border.png') top center repeat-x;
        }
        .l-column__sub {
            margin-bottom: 20px;
        }
        .ttl-header {
            display: none;
        }
    }
    #conf {
        max-width: 588px;
    }
    .contents-url {
        padding-right: 20px;
        @media (max-width: $breakMaxPhone) {
            padding-right: 0;
        }
    }
    .txt-url {
        padding: 10px;
        border: 1px solid $gray;
        word-wrap: break-word;
        word-break: break-all;
    }
    .contents-qr {
        text-align: center;
        img {
            border: 5px solid $green;
        }
    }
}
