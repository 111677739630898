/*
 * Btn Style
*/

// atomic
.btn {
    display: block;
    img {
        @include rspImage;
    }
}

.btn-radius {
    display: block;
    padding: 10px 15px;
    border: 1px solid $gray;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    @media (max-width: $breakMaxFablet) {
        font-size: 14px;
    }
}

.btn-round {
    box-sizing: border-box;
    display: block;
    max-width: 170px;
    line-height: 36px;
    padding: 0 15px;
    border-radius: 24px;
    font-weight: bold;
    font-size: 18px;
    &.yellow {
        background-color: $yellow;
        &:hover {
            background-color: rgb(255, 178, 0);
        }
    }
}

.btn-solid {
    display: block;
    box-sizing: border-box;
    padding: 10px;
    border: none;
    border-radius: 5px;
    text-align: center;
    color: #FFF;
    font-size: 18px;
    font-weight: bold;
    &[class*="icon"] {
        display: -ms-flexbox;display: flex;
        -ms-justify-content: center;justify-content: center;
        -ms-align-items: center;align-items: center;
    }

    &[disabled] {
        opacity: .30;
    }
    &:hover {
        color: #fff;
        transform: translateY(-3px);
        transition: transform .2s, box-shadow .2s;
    }
    &:active {
        color: #fff;
        transform: translateY(0);
        transition: transform .2s, box-shadow .2s;
    }
    &.blue {
        background-color: $blue;
        box-shadow: 0 5px $blueDark;
        &:hover {
            box-shadow: 0 8px $blueDark;
        }
        &:active {
            transform: translateY(0);
            box-shadow: 0 5px $blueDark;
        }
    }
    &.red {
        background-color: $pink;
        box-shadow: 0 5px $pinkDark;
        &:hover {
            box-shadow: 0 8px $pinkDark;
        }
        &:active {
            transform: translateY(0);
            box-shadow: 0 5px $pinkDark;
        }
    }
    &.gray {
        color: $baseBlack;
        background-color: $grayLight;
        box-shadow: 0 5px $grayDark;
        &:hover {
            box-shadow: 0 8px $grayDark;
        }
        &:active {
            transform: translateY(0);
            box-shadow: 0 5px $grayDark;
        }
    }
    &.green {
        background-color: darken($green, 5%);
        box-shadow: 0 5px darken($green, 10%);
        &:hover {
            box-shadow: 0 8px darken($green, 10%);
        }
        &:active {
            transform: translateY(0);
            box-shadow: 0 5px darken($green, 10%);
        }
    }
    // Facebook
    &.btn-fb {
        background-color: #39599f;
        box-shadow: 0 5px #254282;
        &:hover {
            box-shadow: 0 8px #254282;
        }
        &:active {
            box-shadow: 0 0;
        }
    }

    // Twitter
    &.btn-tw {
        background-color: #45b0e3;
        box-shadow: 0 5px #2391c5;
        &:hover {
            box-shadow: 0 8px #2391c5;
        }
        &:active {
            box-shadow: 0 0;
        }
    }

    // Line
    &.btn-li {
        background-color: #00b900;
        box-shadow: 0 5px #009b00;
    }
    &:hover {
        box-shadow: 0 8px #009b00;
    }
    &:active {
        box-shadow: 0 0;
    }

    // 前へ
    &.prev {
        color: #fff;
        background-color: #cccccc;
        box-shadow: 0 5px #aaaaaa;
        &:hover {
            box-shadow: 0 8px #aaaaaa;
        }
        &:active {
            transform: translateY(0);
            box-shadow: 0 5px #aaaaaa;
        }
    }
}


/* 修正の予定 */

.btn-login {
    background: $blue;
    box-shadow: 0 5px $blueDark;
    &:before {
        background: url($imgPath+'/icon_key.png') no-repeat 50% 50%;
        background-size: contain;
    }
    &:hover {
        box-shadow: 0 8px $blueDark;
    }
    &:active {
        box-shadow: 0 0;
    }
}

.btn-registration {
    background: $pink;
    box-shadow: 0 5px $pinkDark;
    &:before {
        background: url($imgPath+'/icon_new.png') no-repeat 50% 50%;
        background-size: contain;
    }
    &:hover {
        box-shadow: 0 8px $pinkDark;
    }
    &:active {
        box-shadow: 0 0;
    }
}

/* Module */
.thumbnail-link {
    display: block;
    width: 100%;
    border: 1px solid #fff;
    background: #fff;
    img {
        @include rspImage;
        display: block;
        margin: 0 auto;
        width: 100%;
    }
    .title, .text {
        margin: 0;
        line-height: 1.2;
        font-size: 14px;
        @media (max-width: $breakMaxFablet) {
            @include ellipsis;
            font-size: 12px;
        }
    }
    .title {
        padding: 8px 5px 5px;
        margin-bottom: 0;
        min-height: 3.5em;
        font-weight: bold;
    }
    .text {
        padding: 0 5px 8px;
        display: block;
    }
}

.btn-nav {
    width: 190px;
    height: 90px;
    background: #fff;
    font-size: 17px;
    font-weight: bold;
    color: #000;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    &.small {
        font-size: 14px;
    }
    &.active {
        position: relative;
        background: $yellow;
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -15px;
            left: 50%;
            width: 0;
            height: 0;
            transform: translate(-50%, 0);
            border-style: solid;
            border-width: 18px 15px 0 15px;
            border-color: $yellow transparent transparent transparent;
        }
    }
    &:hover {
        background: $yellow;
        transition: all .3s ease;
    }
}

.btn-more {
    display: block;
    text-indent: -9999px;
    background: #FFF url($imgPath+'/btn_more.png') center no-repeat;
    width: 320px;
    height: 45px;
}

.btn-pagetop {
    display: block;
    width: 164px;
    margin: 0 auto;
    @media (max-width: $breakMinPhone) {
        width: 150px;
    }
    img {
        @include rspImage;
    }
}

.btn-fb {
    background-color: #39599f;

    &:active {
        background-color: #2e4982;
    }
}

.btn-tw {
    background-color: #45b0e3;

    &:active {
        background-color: #3a94bf;
    }
}

.btn-horizontal {
    display: -ms-flexbox;display: flex;
    -webkit--ms-justify-content: center;justify-content: center;
    -ms-justify-content: center;justify-content: center;
    .btn,
    .btn-f {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
    .btn-f {
        -ms-flex: 1;flex: 1;
    }
    @media (max-width: $breakMaxPhone) {
        display: block;
        .btn,
        .btn-f {
            display: block;
            margin: 0 auto 20px;
        }
    }
}

.btn-group {
    display: -ms-flexbox;display: flex;
    -ms-flex-wrap: wrap;flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    > .btn-box {
        width: 50%;
        padding: 10px;
        &.wide {
            width: 100%;
        }
    }
    @media (max-width: $breakMaxPhone) {
        display: block;
        > .btn-box {
            text-align: left;
            width: 100%;
        }
    }
}
