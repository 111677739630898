$baseWidth: 1200px;
$baseBgColor: #fff;
$baseBlack: rgb(51, 51, 51);
$baseSpace: 20px;
$imgPath: "../images";
$paddingHeader: 22px;

$green: rgb(191, 213, 0);
$greenLight: rgb(236, 242, 183);
$greenDark: rgb(84, 192, 0);
$yellow: rgb(255, 241, 0);
$pink: rgb(251, 126, 97);
$pinkDark: rgb(255, 89, 51);
$orange: rgb(255, 92, 53);
$blue: rgb(111, 192, 225);
$blueDark: rgb(44, 162, 209);
$aqua: rgb(102,204,255);
$gray: rgb(204, 204, 204);
$grayDark: rgb(170, 170, 170);
$grayLight: lighten($gray, 10%);

$error: rgb(255, 92, 53);
$errorBg: rgb(251, 225, 219);

$fontfamily :"游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
$fontColorAqua: #0ff;

$breakTablet: 1080px;
$breakFablet: 768px;
$breakPhone: 544px;

$breakMaxTablet: $breakTablet;
$breakMaxFablet: $breakFablet;
$breakMaxPhone: $breakPhone;

$breakMinTablet: ($breakTablet + 1);
$breakMinFablet: ($breakFablet + 1);
$breakMinPhone: ($breakPhone + 1);
